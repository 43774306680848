.tippy-box[data-animation="shift-toward-subtle"][data-state="hidden"] {
  opacity: 0;
}

.tippy-box[data-animation="shift-toward-subtle"][data-state="hidden"][data-placement^="top"][data-state="hidden"] {
  transform: translateY(-5px);
}

.tippy-box[data-animation="shift-toward-subtle"][data-state="hidden"][data-placement^="bottom"][data-state="hidden"] {
  transform: translateY(5px);
}

.tippy-box[data-animation="shift-toward-subtle"][data-state="hidden"][data-placement^="left"][data-state="hidden"] {
  transform: translateX(-5px);
}

.tippy-box[data-animation="shift-toward-subtle"][data-state="hidden"][data-placement^="right"][data-state="hidden"] {
  transform: translateX(5px);
}
/*# sourceMappingURL=index.ace09a84.css.map */
